import React, {useState} from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {filterOutDocsWithoutSlugs, filterOutUnlistedDocs, getSEOMetadata} from '../lib/helpers'

import Offcuts from '../components/offcuts'

export const query = graphql`
query WorkPageQuery {
  page: sanityWorkPage(_id: {regex: "/(drafts.|)workPage/"}) {
    projects {
      id
      previewImage {
        asset {
          _id
          metadata {
            dimensions {
              aspectRatio
            }
            palette {
              dominant {
                background
              }
            }
          }           
        }
        alt
      }
      title
      unlisted
      slug {
        current
      }
      categories {
        title
        _id
      }
    }
    title
    seoFields {
      metaTitle
      metaDescription
      metaImage {
        asset {
          url
        }
      }
    }      
  }
}
`

const WorkPage = props => {
  const {data, errors} = props;
  const projects = data.page.projects
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const [categoryFilterVisible, setCategoryFilterVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const onCategoryTriggerClick = () => {
    setCategoryFilterVisible(!categoryFilterVisible);
  }
  const onCategoryItemClick = (e, category) => {
    setSelectedCategory(category);
    setCategoryFilterVisible(!categoryFilterVisible);
  }
  const onFilterReset = () => {
    setSelectedCategory(undefined);
    setCategoryFilterVisible(!categoryFilterVisible);
  }
  let projectNodes = data && projects && projects.filter(filterOutDocsWithoutSlugs).filter(filterOutUnlistedDocs);
  let allCategories = [];
  {projectNodes.map(project => {
    allCategories.push(...project.categories)
  })}
  //Remove duplicate categories
  allCategories = allCategories.filter((category, index, self) =>
    index === self.findIndex((t) => (
      t._id === category._id
    ))
  )

  //Filter projects
  const filterProjects = () => {
    const filteredProjects = projectNodes.filter((project) => {
      let found = false;
      for (let i=0;i<project.categories.length;i++) {
        if (project.categories[i]._id === selectedCategory._id) {
          found = true;
          break;
        }
      }
      return found;
    });
    return filteredProjects;
  }
  projectNodes = selectedCategory ? filterProjects() : projectNodes;

  return (
    <Layout>
      <SEO SEOMetadata={getSEOMetadata(data.page)}/>
      <Container>
        <div className="work-header">
          <div className="contents">
            <h4 className="type-body-display">A collection of recent favourites</h4>
            <h4 className="type-subheading category-trigger" onClick={(e) => onCategoryTriggerClick()}>Project type {selectedCategory && <span>— {selectedCategory.title} </span>}<span className={`arrow${categoryFilterVisible ? ' -open' : ''}`}>▾</span></h4>
            {categoryFilterVisible && allCategories.length && (
              <ul className="project-categories">
                <li className={`type-subheading item${!selectedCategory ? ' -active' : ''}`}  key="all" onClick={() => onFilterReset()}>All</li>
                {allCategories.map(category => (
                  <li className={`type-subheading item${selectedCategory && selectedCategory._id === category._id ? ' -active' : ''}`} key={category._id} onClick={(e) => onCategoryItemClick(e, category)}>
                    {category.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {
        }
        {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes} />}
        <Offcuts />
      </Container>
    </Layout>
  )
}

export default WorkPage
