import React, {useEffect, useState} from 'react';
import {StaticQuery, graphql} from 'gatsby'
import {isMobile, inBrowser} from '../lib/helpers'
import BlockContent from './block-content'
import Figure from './figure'
import moment from 'moment';

export const query = graphql`
query OffcutsQuery {
    page: sanityWorkPage(_id: {regex: "/(drafts.|)workPage/"}) {
        offcuts {
            title
            link
            _rawDescription
            image {
                alt
                caption
                isPortrait
                asset {
                    _id
                    metadata {
                        dimensions {
                          aspectRatio
                        }
                        palette {
                          dominant {
                            background
                          }
                        }
                    }                     
                }
            }
        }
    }
}
`

const Offcuts = () => {
    return (
        <StaticQuery
            query={query}
            render={
                (data) => {
                    const years = moment().diff('2012-01-01', 'years');
                    const offcuts = data.page.offcuts;
                    const [currentOffcut, setCurrentOffcut] = useState(null);
                    const [cursorActive, setCursor] = useState(false);
                    const handleOffcutMouseOver = (offcut) => {
                        setCurrentOffcut(offcut)
                        handleCursor();
                    }
                    function extractHostname(url) {
                        var hostname;
                        //find & remove protocol (http, ftp, etc.) and get hostname
                        if (url.indexOf("//") > -1) {
                            hostname = url.split('/')[2];
                        }
                        else {
                            hostname = url.split('/')[0];
                        }
                        //find & remove port number
                        hostname = hostname.split(':')[0];
                        //find & remove "?"
                        hostname = hostname.split('?')[0];
                        return hostname;
                    }
                    const handleOffcutMouseOut = () => {
                        setCurrentOffcut(null)
                        handleCursor();
                    }
                    const setCursorPosition = (e) => {
                        if (!isMobile() && (currentOffcut && currentOffcut.link)) {
                            const cursor = inBrowser() ? document.getElementsByClassName('offcuts-cursor')[0] : undefined;
                            const height = cursor.clientHeight;
                            const width = cursor.clientWidth;
                            cursor.style.top = `${e.clientY - (height / 2)}px`;
                            cursor.style.left = `${e.clientX - (width / 2)}px`;                        
                        }
                    }
                    const handleCursor = () => {
                        if (!isMobile()) {
                            setCursor(!cursorActive);
                        }
                    }
                    useEffect(() => {
                        const cursor = inBrowser() ? document.getElementsByClassName('offcuts-cursor')[0] : undefined;
                    });                    
                    return (
                        <section className="offcuts-section">
                            <div className="offcuts-header">
                                <div className="content">
                                    <h3 className="type-subheading">Offcuts going waaaaay back</h3>
                                    <h4 className="type-body-display">An archive of additional work, experiments and straight-up rejects from the past {years} years.</h4>
                                </div>
                            </div>
                            {
                                offcuts && (
                                    <ul className={`offcuts${currentOffcut ? ' -fade-children' : ''}`}>
                                        {!isMobile() && (
                                            <div className="offcuts-information">
                                                {currentOffcut && (
                                                    <>
                                                        {currentOffcut._rawDescription ? (
                                                            <>
                                                                <h4 className="type-body">{currentOffcut.title}</h4>
                                                                <BlockContent blocks={currentOffcut._rawDescription || []} />
                                                            </>
                                                        ) : (
                                                            <h4 className="type-body">{currentOffcut.title}</h4>
                                                        )}
                                                    </>
                                                )}
                                            </div>   
                                        )}
                                        {offcuts.map((offcut) => {
                                            const image = <Figure node={offcut.image} maxWidth={offcut.image.isPortrait ? 3000 : 4000} caption={null} />;
                                            return (
                                                <li className={`offcut${offcut.image.isPortrait ? ' -portrait' : ''}`} key={offcut.image.asset._id}>
                                                    <div 
                                                        className="inner" 
                                                        onMouseEnter={() => handleOffcutMouseOver(offcut)} 
                                                        onMouseLeave={() => handleOffcutMouseOut()}
                                                        onMouseMove={(e) => setCursorPosition(e)}
                                                    >
                                                        {offcut.link ? (
                                                            <a href={offcut.link} target="_blank" className="offcut-link">{image}</a>
                                                            ) : (
                                                            image
                                                        )}
                                                        {isMobile() && (
                                                            offcut.link ? <a href={offcut.link} target="_blank" className="type-body link">{offcut.title}</a> : <p className="type-body">{offcut.title}</p>
                                                        )}
                                                        {isMobile() && offcut._rawDescription && (
                                                            <BlockContent blocks={offcut._rawDescription || []} />
                                                        )}
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>  
                                )
                            }
                            <p className={`offcuts-cursor -active${currentOffcut && currentOffcut.link ? ' -arrow' : ''}`}>
                                {currentOffcut && currentOffcut.link && (
                                    <span className="type-body">{extractHostname(currentOffcut.link)}</span>
                                )}
                            </p>
                        </section>
                    )
                }
            }
        />
    )
}

export default Offcuts;