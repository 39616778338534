import React from 'react'
import {cn, buildImageObj, inBrowser} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'
import {Link} from 'gatsby'
import Figure from './figure'

const ProjectPreview = (props) => {
  const handleHeaderScroll = () => {
    const windowHeight = inBrowser() ? window.innerHeight : undefined;
    const growCircle = () => {
      const projects = inBrowser() ? document.querySelectorAll('.js-project-preview') : undefined;
      const scrollY = inBrowser() ? window.scrollY : undefined;
      projects.forEach((project, index) => {
        const circle = project.querySelector('.js-growing-circle');
        const offset = project.getBoundingClientRect().top;
        let imageScale;
        if (offset < (windowHeight * -1) || (imageScale >= 1)) {
          //Bail out when it's off screen
          return;
        } else if (offset < windowHeight) {
          const offsetNormalise = project.offsetTop - windowHeight;
          const scrolledDistance = scrollY - offset - offsetNormalise > 0 ? scrollY - offset - offsetNormalise : 0;
          const scrolled = scrolledDistance / (windowHeight * 0.5);
          const scaleModifer = 0.6;
          const scaleConstant = 0;
          imageScale = (scrolled * scaleModifer) + scaleConstant;
          if (imageScale > 1) {
            imageScale = 1;
            project.classList.add('grown')
          } else {
            project.classList.remove('grown')
          }
          const scaleCSS = 'scale(' + imageScale  + ')';
          circle.style.transform = scaleCSS;
        }
      })
    }
    if (inBrowser()) {
      window.addEventListener('scroll', () => {
        requestAnimationFrame(() => {
          growCircle();
        })
      })
    }
  }
  handleHeaderScroll();
  return (
    <Link to={`/projects/${props.slug.current}`}>
      <div className="project-preview js-project-preview">
        {props.previewImage && props.previewImage.asset && (
          <div className="image-wrapper">
            <Figure node={props.previewImage} maxWidth={1500} quality={95} square />
          </div>
        )}
        <div className="project-info-wrapper">
          <div className="growing-circle js-growing-circle"></div>
          <div className="interior">
            <h3 className="type-section">{props.title}</h3>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ProjectPreview
